import { useState, useEffect, useCallback } from 'react';

export enum DeviceType {
  mobile = 'mobile',
  tab = 'tab',
  laptop = 'laptop',
  desktop = 'desktop',
  tv = 'tv',
}
const MobileWidth = 480;
const TabWidth = 768;
const LaptopWidth = 1024;
const DesktopWidth = 1200;
// Above 1200 is TV or Extra Large screens
export default function useDeviceType() {
  const getDeviceType = useCallback((): DeviceType => {
    let deviceTypeVal = DeviceType.laptop;
    const hasWindow = typeof window !== 'undefined';
    const width = hasWindow ? window.innerWidth : undefined;
    if (!width) {
      deviceTypeVal = DeviceType.laptop;
    } else if (width && width <= MobileWidth) {
      deviceTypeVal = DeviceType.mobile;
    } else if (width && width <= TabWidth) {
      deviceTypeVal = DeviceType.tab;
    } else if (width && width <= LaptopWidth) {
      deviceTypeVal = DeviceType.laptop;
    } else if (width && width <= DesktopWidth) {
      deviceTypeVal = DeviceType.desktop;
    } else {
      deviceTypeVal = DeviceType.tv;
    }
    return deviceTypeVal;
  }, []);

  const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType());

  useEffect(() => {
    window.addEventListener('resize', () => setDeviceType(getDeviceType()));
    return () =>
      window.removeEventListener('resize', () =>
        setDeviceType(getDeviceType())
      );
  }, [getDeviceType]);

  const isMobile = deviceType === DeviceType.mobile;
  const isTab = deviceType === DeviceType.tab;
  const isLaptop = deviceType === DeviceType.laptop;
  const isDesktop = deviceType === DeviceType.desktop;
  const isTv = deviceType === DeviceType.tv;
  const isMobileOrTab =
    deviceType === DeviceType.tab || deviceType === DeviceType.mobile;
  const isScreen =
    deviceType === DeviceType.laptop ||
    deviceType === DeviceType.desktop ||
    deviceType === DeviceType.tv;

  return {
    deviceType,
    isMobile,
    isTab,
    isLaptop,
    isDesktop,
    isTv,
    isScreen,
    isMobileOrTab,
  };
}
