import React from 'react';
import { MenuProps } from 'antd';
// import { LinkOutlined } from '@ant-design/icons';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import Gallery from './pages/gallery/gallery';
import Book from './pages/book/book';
import News from './pages/news/news';

export const homePageKey = 'home';
export const items: MenuProps['items'] = [
  {
    label: 'Home',
    key: 'home',
  },
  {
    label: 'Gallery',
    key: 'gallery',
  },
  {
    label: 'News',
    key: 'news',
  },
  // {
  //   label: (
  //     <a
  //       href="https://www.instamojo.com/@brahmatemple"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       Donate
  //     </a>
  //   ),
  //   key: 'donate',
  //   icon: <LinkOutlined />,
  // },
  {
    label: 'My Book',
    key: 'book',
  },
  {
    label: 'About Us',
    key: 'about',
  },
  {
    label: 'Contact Us',
    key: 'contact',
  },
];

export const isBannerRequired = (key: string) => key === homePageKey;

export const isSidebarRequired = (key: string) =>
  key !== 'Donate' && key !== 'contact';

export const getPage = (key: string) => {
  switch (key) {
    case 'home':
      return <Home />;
    case 'gallery':
      return <Gallery />;
    case 'news':
      return <News />;
    case 'book':
      return <Book />;
    case 'about':
      return <About />;
    case 'contact':
      return <Contact />;
    default:
      return <Home />;
  }
};
