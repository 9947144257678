import React from 'react';
import { Image, Typography } from 'antd';

import contact from '../../images/contactpage.jpeg';

import './contact.scss';

const { Paragraph } = Typography;

const Contact = () => (
  <>
    <Typography.Title level={3}>Contact Us</Typography.Title>
    <div className="contact">
      <div>
        <Image src={contact} preview={false} />
      </div>
      <div>
        <Paragraph>
          <Typography.Title level={4} className="headline">
            Address
          </Typography.Title>
          Shree Brahma Temple National Highway 34 Village Shyampur Kangri,
          Pathari Forest Range, Haridwar Uttarakhand 249408
          <br />
          (about 7 Kms.from Chandi Mata Mandir)
          <Typography.Title level={5} className="headline">
            Email
          </Typography.Title>
          <a href="mailto:Brahmanandantrust@gmail.com">
            Brahmanandantrust@gmail.com
          </a>
          <Typography.Title level={5} className="headline">
            Call
          </Typography.Title>
          +91-7819894180
        </Paragraph>
      </div>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.8296730724437!2d78.18432419999999!3d29.869185599999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390945efa8c9fbf1%3A0x36ad62594624e5f5!2sBrahma%20ji%20Temple%20Haridwar!5e0!3m2!1sen!2sau!4v1686545518946!5m2!1sen!2sau"
          className="map"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer"
          title="Brahma Temple Haridwar"
        />
      </div>
    </div>
  </>
);

export default Contact;
