import React from 'react';
import { Typography } from 'antd';

import './about.scss';

const { Paragraph } = Typography;

const About = () => (
  <>
    <Typography.Title level={3}>About Us</Typography.Title>
    <Paragraph className="pagetext">
      There are so many religions in the world but most people mainly follow
      Christianity,Judaism, Budhism, Muslim,Sikh and Hinduism. Now,Christianity
      came into existence around 2500 years ago,Judaism about 3000 years
      old,Budhism around 2000 years ago, Muslim religion around 1400 years ago,
      Sikhism around 550 years ago but Hinduism is oldest religion with roots
      and customs dating back more than 4000 years which means Hinduism is the
      oldest religion and all other religions came out of Hinduism but this
      world existed even prior to that. Question arises who created this world ?
      When Sun,Stars. Moon into existence ? Who created humans,animals, birds,
      trees, plants etc.? None of the religions answers this question except
      Hinduism.
    </Paragraph>
    <Paragraph className="pagetext">
      There are various myths/misconceptions in Hindu mythology. Like, there is
      no temple of Lord Brahma because of a curse by his wife Savitri, Lord
      Shiva etc… After a thorough study and a lot of research, I could conclude
      that stories of the curse to Lord Brahma are just myth. To prove, I
      decided to construct the temple of Lord Brahma and this was done without
      any financial help from anybody, after selling my own residential house
      and my whole life savings.
    </Paragraph>
    <Paragraph className="pagetext">
      Temple of Lord Brahma has been constructed in
      <b> Village Shyampur Kangri </b>
      which is 10 Kms from Haridwar on Chandi Devi Mandir
      <b> in Haridwar </b>
      after
      <b> Pran Prithishtha of idols on Feb.10, 2019. </b>I wish to construct
      more temples of Lord Brahma in other states also subject to financial help
      from Lord Brahma/His devotees.
    </Paragraph>
  </>
);

export default About;
