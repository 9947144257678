import React from 'react';
import { Image, Typography } from 'antd';

import brahma from '../../images/brahma.jpeg';
import './sidebarSection.scss';

const { Paragraph } = Typography;
type Props = {
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
};
const SidebarSection: React.FC<Props> = ({ current, setCurrent }: Props) => (
  <>
    <Image src={brahma} preview={false} />
    {current !== 'news' && (
      <>
        <Typography.Title level={5}>Latest Updates</Typography.Title>
        <Paragraph className="news">
          Another temple of Lord Brahma was inaugurated on June 21, 2023 at Mata
          Vaishno Dham , Chintpurni road, Hoshiarpur.
          <span
            onClick={() => setCurrent('news')}
            aria-hidden="true"
            className="link"
          >
            Read More
          </span>
        </Paragraph>
      </>
    )}
  </>
);
export default SidebarSection;
