import React from 'react';
import { Typography } from 'antd';
import ImageGallery from 'react-image-gallery';

import './gallery.scss';

const images = [
  {
    original: './gallery/IMG2.jpeg',
    thumbnail: './gallery/IMG2.jpeg',
  },
  {
    original: './gallery/IMG0.jpeg',
    thumbnail: './gallery/IMG0.jpeg',
  },
  {
    original: './gallery/IMG1.jpeg',
    thumbnail: './gallery/IMG1.jpeg',
  },
  {
    original: './gallery/IMG3.jpeg',
    thumbnail: './gallery/IMG3.jpeg',
  },
  {
    original: './gallery/IMG4.jpeg',
    thumbnail: './gallery/IMG4.jpeg',
  },
  {
    original: './gallery/IMG5.jpeg',
    thumbnail: './gallery/IMG5.jpeg',
  },
  {
    original: './gallery/IMG6.jpeg',
    thumbnail: './gallery/IMG6.jpeg',
  },
  {
    original: './gallery/IMG7.jpg',
    thumbnail: './gallery/IMG7.jpg',
  },
  {
    original: './gallery/IMG8.jpg',
    thumbnail: './gallery/IMG8.jpg',
  },
  {
    original: './gallery/IMG9.jpg',
    thumbnail: './gallery/IMG9.jpg',
  },
];
const Gallery = () => (
  <>
    <Typography.Title level={3}>
      Mesmerizing Images of Brahma Temple
    </Typography.Title>
    <div className="gallery">
      <ImageGallery
        items={images}
        disableThumbnailScroll
        autoPlay
        slideDuration={300}
      />
    </div>
  </>
);
export default Gallery;
