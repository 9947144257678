import React from 'react';
import { Typography } from 'antd';
import ImageGallery from 'react-image-gallery';

import './news.scss';

const images = [
  {
    original: './news/templehsp/img0.JPG',
    thumbnail: './news/templehsp/img0.JPG',
  },
  {
    original: './news/templehsp/img1.JPG',
    thumbnail: './news/templehsp/img1.JPG',
  },
  {
    original: './news/templehsp/img2.JPG',
    thumbnail: './news/templehsp/img2.JPG',
  },
  {
    original: './news/templehsp/img3.JPG',
    thumbnail: './news/templehsp/img3.JPG',
  },
  {
    original: './news/templehsp/img4.JPG',
    thumbnail: './news/templehsp/img4.JPG',
  },
  {
    original: './news/templehsp/img5.JPG',
    thumbnail: './news/templehsp/img5.JPG',
  },
];

const { Paragraph } = Typography;
const News = () => (
  <div className="news">
    <Typography.Title level={5} className="date">
      June 21, 2023
    </Typography.Title>
    <Paragraph>
      <q>
        Another temple of Lord Brahma was inaugurated on June 21, 2023 at Mata
        Vaishno Dham , Chintpurni road, Hoshiarpur . Idols of Lord Brahma, Mata
        Saraswati, Mata Gayatri along with Lord Vishnu and Lord Shiva was placed
        after Pran prathishtha of idols and Kalsh Yatra of the town.
      </q>
    </Paragraph>
    <div className="gallery">
      <ImageGallery
        items={images}
        disableThumbnailScroll
        autoPlay
        slideDuration={300}
      />
    </div>
  </div>
);
export default News;
