export const bookChapters = [
  {
    title: 'Chapter 1 (Part 1)',
    description: 'Lord Brahma : Facts and Myths',
    pages: [
      {
        original: './book/chap1part1/pg1.png',
        thumbnail: './book/chap1part1/pg1.png',
      },
      {
        original: './book/chap1part1/pg2.png',
        thumbnail: './book/chap1part1/pg2.png',
      },
      {
        original: './book/chap1part1/pg3.png',
        thumbnail: './book/chap1part1/pg3.png',
      },
      {
        original: './book/chap1part1/pg4.png',
        thumbnail: './book/chap1part1/pg4.png',
      },
      {
        original: './book/chap1part1/pg5.png',
        thumbnail: './book/chap1part1/pg5.png',
      },
      {
        original: './book/chap1part1/pg6.png',
        thumbnail: './book/chap1part1/pg6.png',
      },
      {
        original: './book/chap1part1/pg7.png',
        thumbnail: './book/chap1part1/pg7.png',
      },
    ],
  },
  {
    title: 'Chapter 1 (Part 2)',
    description: 'Lord Brahma : Facts and Myths ',
    pages: [
      {
        original: './book/chap1part2/pg8.png',
        thumbnail: './book/chap1part2/pg8.png',
      },
      {
        original: './book/chap1part2/pg9.png',
        thumbnail: './book/chap1part2/pg9.png',
      },
      {
        original: './book/chap1part2/pg10.png',
        thumbnail: './book/chap1part2/pg10.png',
      },
      {
        original: './book/chap1part2/pg11.png',
        thumbnail: './book/chap1part2/pg11.png',
      },
      {
        original: './book/chap1part2/pg12.png',
        thumbnail: './book/chap1part2/pg12.png',
      },
      {
        original: './book/chap1part2/pg13.png',
        thumbnail: './book/chap1part2/pg13.png',
      },
      {
        original: './book/chap1part2/pg14.png',
        thumbnail: './book/chap1part2/pg14.png',
      },
      {
        original: './book/chap1part2/pg15.png',
        thumbnail: './book/chap1part2/pg15.png',
      },
      {
        original: './book/chap1part2/pg16.png',
        thumbnail: './book/chap1part2/pg16.png',
      },
      {
        original: './book/chap1part2/pg17.png',
        thumbnail: './book/chap1part2/pg17.png',
      },
    ],
  },
  {
    title: 'Chapter 1 (Part 3)',
    description: 'Lord Brahma : Facts and Myths',
    pages: [
      {
        original: './book/chap1part3/pg18.png',
        thumbnail: './book/chap1part3/pg18.png',
      },
      {
        original: './book/chap1part3/pg19.png',
        thumbnail: './book/chap1part3/pg19.png',
      },
      {
        original: './book/chap1part3/pg20.png',
        thumbnail: './book/chap1part3/pg20.png',
      },
      {
        original: './book/chap1part3/pg21.png',
        thumbnail: './book/chap1part3/pg21.png',
      },
    ],
  },
  {
    title: 'Chapter 2',
    description: 'Why we are sent to Prithvi Loka',
    pages: [
      {
        original: './book/chap2/pg1.png',
        thumbnail: './book/chap2/pg1.png',
      },
      {
        original: './book/chap2/pg2.png',
        thumbnail: './book/chap2/pg2.png',
      },
      {
        original: './book/chap2/pg3.png',
        thumbnail: './book/chap2/pg3.png',
      },
      {
        original: './book/chap2/pg4.png',
        thumbnail: './book/chap2/pg4.png',
      },
      {
        original: './book/chap2/pg5.png',
        thumbnail: './book/chap2/pg5.png',
      },
      {
        original: './book/chap2/pg6.png',
        thumbnail: './book/chap2/pg6.png',
      },
      {
        original: './book/chap2/pg7.png',
        thumbnail: './book/chap2/pg7.png',
      },
    ],
  },
  {
    title: 'Chapter 3',
    description: 'Getting Yog power through meditation',
    pages: [
      {
        original: './book/chap3/pg1.png',
        thumbnail: './book/chap3/pg1.png',
      },
      {
        original: './book/chap3/pg2.png',
        thumbnail: './book/chap3/pg2.png',
      },
      {
        original: './book/chap3/pg3.png',
        thumbnail: './book/chap3/pg3.png',
      },
      {
        original: './book/chap3/pg4.png',
        thumbnail: './book/chap3/pg4.png',
      },
      {
        original: './book/chap3/pg5.png',
        thumbnail: './book/chap3/pg5.png',
      },
      {
        original: './book/chap3/pg6.png',
        thumbnail: './book/chap3/pg6.png',
      },
      {
        original: './book/chap3/pg7.png',
        thumbnail: './book/chap3/pg7.png',
      },
    ],
  },
  {
    title: 'Chapter 4',
    description: 'How to meditate',
    pages: [
      {
        original: './book/chap4/pg1.png',
        thumbnail: './book/chap4/pg1.png',
      },
      {
        original: './book/chap4/pg2.png',
        thumbnail: './book/chap4/pg2.png',
      },
      {
        original: './book/chap4/pg3.png',
        thumbnail: './book/chap4/pg3.png',
      },
      {
        original: './book/chap4/pg4.png',
        thumbnail: './book/chap4/pg4.png',
      },
      {
        original: './book/chap4/pg5.png',
        thumbnail: './book/chap4/pg5.png',
      },
      {
        original: './book/chap4/pg6.png',
        thumbnail: './book/chap4/pg6.png',
      },
      {
        original: './book/chap4/pg7.png',
        thumbnail: './book/chap4/pg7.png',
      },
      {
        original: './book/chap4/pg8.png',
        thumbnail: './book/chap4/pg8.png',
      },
      {
        original: './book/chap4/pg9.png',
        thumbnail: './book/chap4/pg9.png',
      },
      {
        original: './book/chap4/pg10.png',
        thumbnail: './book/chap4/pg10.png',
      },
    ],
  },
];
