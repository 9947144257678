import React from 'react';
import { ConfigProvider } from 'antd';

import './App.scss';
import colors from './colors.module.scss';
import Template from './template';

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        colorBgBase: colors.backgroundPrimary,
        colorTextBase: colors.textPrimary,
        colorPrimary: colors.linkActivePrimary,
        colorLink: colors.linkPrimary,
        colorTextHeading: colors.headingPrimary,
      },
      components: {
        Layout: {
          colorBgHeader: colors.backgroundPrimary,
          colorBgBody: colors.backgroundPrimary,
        },
      },
    }}
  >
    <Template />
  </ConfigProvider>
);
export default App;
