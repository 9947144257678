import React from 'react';
import { Typography } from 'antd';
import './footerSection.scss';

// import Donate from '../../images/donate.png';

const { Paragraph } = Typography;

const FooterSection = () => (
  <>
    <div>
      <Paragraph>
        <Typography.Title level={4}>CONTACT US</Typography.Title>
        <Typography.Title level={5}>Email</Typography.Title>
        <a href="mailto:Brahmanandantrust@gmail.com" className="emailTo">
          Brahmanandantrust@gmail.com
        </a>
        <Typography.Title level={5}>Call</Typography.Title>
        +91-7819894180
      </Paragraph>
    </div>
    <div>
      <Paragraph>
        <Typography.Title level={4}>LOCATION</Typography.Title>
        Shree Brahma Temple National Highway 34 Village Shyampur Kangri, Pathari
        Forest Range, Haridwar Uttarakhand 249408
        <br />
        <br />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3459.8296730724437!2d78.18432419999999!3d29.869185599999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390945efa8c9fbf1%3A0x36ad62594624e5f5!2sBrahma%20ji%20Temple%20Haridwar!5e0!3m2!1sen!2sau!4v1686545518946!5m2!1sen!2sau"
          className="map"
          allowFullScreen={false}
          loading="lazy"
          referrerPolicy="no-referrer"
          title="Brahma Temple Haridwar"
        />
      </Paragraph>
    </div>
    {/* <div
      className="donate"
    >
      <Paragraph>
        <a
          href="https://www.instamojo.com/@brahmatemple"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image src={Donate} preview={false} />
        </a>
      </Paragraph>
    </div> */}
  </>
);

export default FooterSection;
