import React from 'react';
import { Typography } from 'antd';

import './home.scss';

const { Paragraph } = Typography;

const Home = () => (
  <>
    <Typography.Title level={3}>BRAHMA TEMPLE - HARIDWAR</Typography.Title>
    <Paragraph>
      <q>
        His name is Om and He created this universe with all its gunas (sattva,
        rajas and tamas) <br />
        He gave it all form, He is universal, He is Brahma (God of spirituality)
        and I worship Him!
      </q>
    </Paragraph>
    <Paragraph className="pagetext">
      Main source of knowledge about Hinduism are Vedas and Puranas. Puransas
      are about 2000 years old and mainly comprises of stories of dieties. Vedas
      are about 4000 years old. As per Hindu mythology, Lord Brahma is the
      creator of this world, Lord Vishnu is the preserver of this world and Lord
      Shiva is the destroyer of this world. Surprisingly, we see so many temples
      of Lord Vishnu, his reincarnations (Lord Rama & Lord Krishna), Lord Shiva,
      Lord Ganesha, other goddesses etc. but we don&apos;t see temples of Lord
      Brahma, except one main temple at Pushkar (Rajasthan) besides other small
      temples in southern part of India and one in Nepal and Indonesia. If we
      read out Vedas, it is observed that a lot of importance is given to Lord
      Brahma, Inder,Sun, Moon, Agni, Varun, little mention of Lord Vishnu and
      Lord Shiva (as Rudra dev). We don&apos;t see temples of deities mentioned
      in Vedas but we see most temples of deities mentioned in Puranas which
      were written just about 2000 years ago and much after the Vedas were
      written. Why it happened ! Answer to this question has been tried to be
      given in my book about Lord Brahma to the best of my knowledge and belief
      after a lot of readings/research. Also readers are suggested to visit
      <a
        href="https://www.spiritualresearchfoundation.org/"
        target="_blank"
        rel="noreferrer"
      >
        www.spiritualresearchfoundation.com
      </a>
      for detailed knowledge on spirituality
    </Paragraph>
  </>
);

export default Home;
